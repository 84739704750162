import { CreateExport } from "@elton/types";
import { SearchInput, Switch } from "@elton/ui/core";
import { NumberFormatter } from "@elton/utils";

import { ExportForm } from "@/components/forms/export-form/export-form";
import {
  TopSectionColumn,
  TopSectionDescription,
  TopSectionHeading,
  TopSectionRow,
} from "@/components/sections/top-section";
import { useSessionColumns } from "@/components/tables/columns/charge-session-columns";
import { DynamicTable } from "@/components/tables/dynamic-table/dynamic-table";
import { useDateRange } from "@/components/tables/dynamic-table/hooks/use-date-range";

import { useSessionSearchParamsUpdater } from "./use-session-search-params-updater";

import PostHogAdapter from "@/adapters/posthog-adapter.ts";
import { useCreateSessionsExportMutation } from "@/api/mutations/exports/create-sessions-export-mutation";
import {
  Route as ChargeSessionsRoute,
  SessionsParams,
} from "@/routes/_authenticated/companies/$companyId/_dashboard/sessions";
import { T, useT } from "@transifex/react";

export const SessionsPage: React.FC = () => {
  const t = useT();
  const searchParams = ChargeSessionsRoute.useSearch<SessionsParams>();
  const loaderData = ChargeSessionsRoute.useLoaderData();
  const columns = useSessionColumns();

  const dateRange = useDateRange(searchParams);

  const createSessionsExport = useCreateSessionsExportMutation(
    loaderData.company.id,
  );

  const { updateSearchFilter, updateDateRange, updateIncludeChildren } =
    useSessionSearchParamsUpdater(ChargeSessionsRoute.fullPath);

  const handleCreateSessionExport = async (
    data: CreateExport,
    format: "csv" | "xlsx",
  ) => {
    await createSessionsExport.mutateAsync({
      ...data,
      filter: searchParams.filter,
      format,
      includeChildren: searchParams.includeChildren,
    });
    PostHogAdapter.logEvent("export", { format, ...data });
  };

  return (
    <>
      <TopSectionRow className="mb-8">
        <TopSectionColumn>
          <TopSectionHeading>
            <T _str="Sessions" />
          </TopSectionHeading>
          <TopSectionDescription>
            <T _str="Overview of your company's charging session. Here you can analyse and get a grasp of the charging sessions that your employees have made." />
          </TopSectionDescription>
        </TopSectionColumn>
      </TopSectionRow>
      <div className="relative w-full">
        <DynamicTable
          renderToolbar={() => {
            return (
              <div className="flex-grow">
                <div className="flex flex-wrap items-start gap-4">
                  <SearchInput
                    defaultValue={searchParams.filter}
                    containerClassName="w-full sm:max-w-96"
                    placeholder={t("Search") + ".."}
                    onChange={updateSearchFilter}
                  />
                </div>

                <div className="flex items-center gap-4">
                  <ExportForm
                    data={{
                      dateFrom: dateRange.range.from,
                      dateTo: dateRange.range.to,
                    }}
                    onChangeDate={(dateRange) => updateDateRange(dateRange)}
                    onCreateExport={handleCreateSessionExport}
                  />
                  {loaderData.company.canBeParent && (
                    <div className="flex items-center space-x-2 h-full">
                      <span className="ml-2 text-sm">
                        {t("Include Branches")}
                      </span>
                      <Switch
                        checked={searchParams.includeChildren}
                        onCheckedChange={updateIncludeChildren}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          }}
          columns={columns}
          data={loaderData.sessions.data.map((sess) => ({
            ...sess,
            kwh: NumberFormatter.round(sess.kwh),
          }))}
          total={loaderData.sessions.total}
          visibilityState={{
            id: false,
            reference: false,
            departmentName: false,
            station: false,
            registrationNumberNote: false,
            user: false,
          }}
        />
      </div>
    </>
  );
};
